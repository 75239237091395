//
// Buttons
//

button,
.btn {
  @include fontSize(16px);
  padding: 9px 32px;
  border-radius: 2px;
  border: 0;
  font-weight: $font-bold;
  letter-spacing: 0;
  width: 100%;
  line-height: 22px;
  box-sizing: border-box;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    opacity: .8;
    cursor: pointer;
    text-decoration: none;
  }

  @include breakpoint($desktop) {
    width: auto;
  }

  i {
    padding: 0 5px 0 0;
  }

}

.action.btn.primary.checkout {
  display: block;
}

%link-button,
button.link {
  @include fontSize(14px);
  background: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  width: auto;
  border-radius: 0;
  font-weight: $font-regular;
  text-decoration: underline;
}

%primary-button,
button.primary,
button.action-primary,
.button.primary,
.btn.primary {
  background-color: get-color(ui-dark, base);
  border: solid 1px get-color(ui-dark, base);
  box-shadow: 0 1px 2px 0 rgba(33, 43, 54, .1);
  color: get-color(ui-white);
  &:hover {
    color: get-color(ui-white);
    background-color: get-color(ui-dark, dark-90);
  }
}

%secondary-button,
button.secondary,
button.action-secondary,
.btn.secondary {
  color: get-color(ui-dark, base);
  border: 1px solid get-color(ui-dark, base);
  background-color: get-color(ui-white);
  box-shadow: 0 1px 2px 0 rgba(33, 43, 54, .1);
  &:hover {
    background-color: get-color(ui-dark, dark-10);
  }
}

button.finance,
.btn.finance {
  color: get-color(ui-dark, base);
  border: 1px solid get-color(ui-dark, dark-20);
  background-color: get-color(ui-white);
  box-shadow: 0 1px 2px 0 rgba(33, 43, 54, .1);
  position: relative;
  padding: 12px 16px 12px 12px;
  display: block;
  &::after {
    @include fontSize(16px);
    position: absolute;
    right: 12px;
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: $font-bold;
  }
  &:hover {
    background-color: get-color(ui-dark, dark-10);
  }
}

%newsletter__button,
button.newsletter__button {
  color: get-color(ui-white);
  border: 1px solid get-color(ui-dark, dark-80);
  background-color: get-color(ui-dark, dark-80);
  box-shadow: 0 1px 2px 0 rgba(33, 43, 54, .1);
  &:hover {
    background-color: get-color(ui-dark, dark-80);
    color: get-color(ui-white);
  }
}

%tertiary-button {
  background: $text;
  color: $blockBg;
}

%addToCart-button,
button.addToCart,
button.tocart,
action.tocart,
button.checkout,
.btn.checkout {
  color: get-color(ui-white);
  background-color: get-color(ui-green, base);
  box-shadow: 0 1px 2px 0 rgba(33, 43, 54, .1);
  padding: 16px 28px;
  min-width: 260px;

  &.paypal {
    background: get-color(ui-dark, base);
  }
}

%ghost-button,
.btn--ghost {
  border: 1px solid $fieldBorder;
  border-radius: 4px;
  text-align: center;
  background-color: transparent;
  color: $text;
}

%taller-button {
  padding: 17px;
}

%blue-anchor {
  color: $primary;
}

//
// Buttons that do not follow the generic styling
//

.action-register,
.primary .action-register {
  @extend %tertiary-button;
}

.primary .action-login {
  @extend %primary-button;
}

.catalog-category-view .action.tocart {
  @include breakpoint($desktop) {
    width: 80%;
  }
}

//
// Taller button declarations
//

.minicart-wrapper .action.checkout {
  @extend %taller-button;
}

//
// Blue anchor declarations
//

a.btn-remove,
a.action.edit,
table a {
  @extend %blue-anchor;
}
